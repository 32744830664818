<template>
  <div id="ForgotPassword">
    <!-- FORGOT PASSWORD 开始 -->
    <SDialog :title="$t('common.button.forgetPw')" center :visible.sync="forgotPwdVisible" :showFooter="false">
      <StatusMessage title="操作成功">
        <template #content>
          <div class="home_status_content" v-html="$t('home.pw.email')"></div>
        </template>
      </StatusMessage>
    </SDialog>
    <!-- FORGOT PASSWORD 结束 -->
  </div>
</template>

<script>
import { apiReq_reset_mt4_password } from '@/resource'
export default {
  name: 'ForgotPassword',
  props: {
    isDemo: Boolean,
  },
  data() {
    return {
      forgotPwdVisible: false,
      uuid: this.$store.state.common.uuid,
      number: null,
    }
  },
  methods: {
    forgotPwdClick(val) {
      this.number = val
      this.sendResetPasswordEmail().then(result => {
        let vm = this
        if (result) {
          this.forgotPwdVisible = true
        } else {
          this.$message({
            message: vm.$t('home.pw.error'),
            type: 'error',
          })
        }
      })
    },
    sendResetPasswordEmail() {
      const baseUrl = location.protocol + '//' + location.hostname
      return apiReq_reset_mt4_password({
        userId: this.uuid,
        mtAccount: this.number,
        baseUrl: baseUrl,
        isDemo: this.isDemo,
      }).then(resp => {
        if (resp.data.code == 0) {
          if (resp.data.data) {
            console.log('The reset password email is sent out')
            return Promise.resolve(true)
          } else {
            console.log('failed to sent out the reset password')
          }
        } else {
          console.log('Errors happened when applying for the reset password emails')
        }
        return Promise.resolve(false)
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
