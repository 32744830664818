<template>
  <div id="ChangeLeverage">
    <SDrawer title="设置杠杆" :visible.sync="visible" custom-class="change_leverage_drawer">
      <el-form :show-message="false" label-position="top" :rules="formDataRules" :model="formData" ref="formData">
        <div class="top">
          <el-form-item :label="$t('common.field.accNum')">
            <span v-html="accountNumber"></span>
          </el-form-item>
          <el-form-item :label="$t('common.field.currLeverage')" :class="{ 'long-text': ['es', 'pt'].includes(lang) }">
            <span>{{ leverage }} : 1</span>
          </el-form-item>
          <el-form-item :label="$t('common.field.accEquity')">
            <span>{{ equity | formatNumber }}</span>
          </el-form-item>
        </div>

        <el-form-item :label="$t('common.field.newLeverage')" prop="newLeverage">
          <el-select v-model="formData.newLeverage" data-testid="newLeverage">
            <el-option
              name="newLeverage"
              v-for="item in avaliableLeverages"
              :key="item.leverage"
              :value="item.leverage + ' : 1'"
              :data-testid="item.leverage"
            ></el-option>
          </el-select>
        </el-form-item>

        <!-- pro 1000 maintion -->
        <div class="reminder-box" v-if="showPro1000Tnc">
          <div class="desc">{{ $t('home.pro1000.desc1') }}</div>
          <ul class="desc">
            <li v-for="(condition, index) in conditionsText" :key="index">{{ condition }}</li>
          </ul>
          <div class="desc">{{ $t('home.pro1000.desc2') }}</div>
          <div class="desc">{{ $t('home.pro1000.desc3') }}</div>
          <div class="desc">{{ $t('home.pro1000.desc4') }}</div>
        </div>

        <el-form-item prop="tnc" v-if="showTNC" class="tnc">
          <el-checkbox name="tnc" v-model="formData.tnc" data-testid="tncConfirm">
            <i18n
              path="openAdditAcc.byTicking"
              tag="div"
              :class="['tnc_label', { 'long-text': ['de'].includes(lang) }]"
            >
              <template v-slot:tnc>
                <p @click="showTNCText = !showTNCText" data-testid="showTNCText">
                  {{ $t('openAdditAcc.tnc') }}
                </p>
              </template>
            </i18n>
          </el-checkbox>
          <div class="tnc_text_box" v-show="showTNCText" v-html="$t('home.leverage.tnc')"></div>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="resetForm(false)" data-testid="cancelChangeLeverage">
          {{ $t('common.button.cancel') }}
        </el-button>
        <el-button
          @click="submitForm"
          data-testid="confirmChangeLeverage"
          type="primary"
          :class="{ 'long-text-btn': ['pt', 'es', 'de'].includes(lang) }"
        >
          {{ $t('common.button.changeLeverage') }}
        </el-button>
      </template>
    </SDrawer>
    <!-- CHANGE LEVERAGE confirm 弹框开始 -->
    <SDialog title="设置杠杆" :visible.sync="confirmVisible" :showFooter="false">
      <StatusMessage :title="confirmType.title" :type="confirmType.type">
        <template #content>
          <div class="home_status_content" v-html="confirmTips"></div>
        </template>
      </StatusMessage>
    </SDialog>
    <!-- CHANGE LEVERAGE confirm 弹框结束 -->

    <SDialog :visible.sync="checkVisible" center>
      <p>{{ $t('home.leverage.reduceLeverageWarn') }}</p>
      <p>{{ $t('home.leverage.reduceLeverageConfirm') }}</p>
      <template #footer>
        <el-button @click="resetForm(false)" data-testid="cancelDialog">{{ $t('common.button.cancel') }}</el-button>
        <el-button type="primary" @click="updateDemoLeverage" data-testid="updateDemoLeverage">
          {{ $t('common.button.confirm') }}
        </el-button>
      </template>
    </SDialog>
  </div>
</template>

<script>
import { apiQueryAvailableLeverages, apiUpdate_leverage, apiUpdate_leverage_demo } from '@/resource'
import { checkAgreedTNC } from '@/util/validation'
export default {
  name: 'ChangeLeverage',
  props: {
    showTNC: {
      type: Boolean,
      default: true,
    },
    method: String,
  },
  data() {
    const checkNewLeverage = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(''))
      }
      if (value.replace(' : 1', '') == this.leverage) {
        return callback(new Error(''))
      }
      return callback()
    }
    return {
      visible: false,
      confirmVisible: false,
      accountNumber: null,
      leverage: null,
      equity: null,
      avaliableLeverages: [],
      formData: {
        metaTraderLogin: '',
        currentLeverage: '',
        newLeverage: '',
        tnc: false,
      },
      confirmStatus: null,
      formDataRules: {
        tnc: [{ validator: checkAgreedTNC, trigger: 'change' }],
        newLeverage: [{ validator: checkNewLeverage, trigger: 'change' }],
      },
      showTNCText: false,
      showPro1000Tnc: false,
      checkVisible: false,
    }
  },
  watch: {
    visible(bool) {
      this.resetForm(bool)
    },
    'formData.newLeverage': {
      immediate: true,
      handler(newValue) {
        if (newValue === '1000 : 1') {
          this.showPro1000Tnc = true
        } else {
          this.showPro1000Tnc = false
        }
      },
    },
  },
  computed: {
    lang() {
      return this.$store.state.common.lang
    },
    conditionsText() {
      return this.$t('home.pro1000.conditions')
    },
    confirmType() {
      if (this.confirmStatus === 'success' || this.confirmStatus === 'auditSuccess') {
        return {
          title: '操作成功',
          type: 'success',
        }
      } else {
        return {
          title: '操作失败',
          type: 'error',
        }
      }
    },
    confirmTips() {
      const status = this.confirmStatus
      if (status === 'success') {
        return this.$t('home.leverage.changeLeverageConfirm')
      } else if (status === 'auditSuccess') {
        return this.$t('home.leverage.changeLeverageAutoComplete')
      } else if (status === 'reject') {
        return this.$t('home.leverage.changeLeverageReject')
      } else if (status === 'fail') {
        return this.$t('home.leverage.changeLeverageFail')
      } else if (status === 'timeout') {
        return this.$t('home.leverage.changeLeverageDefault', { supportEmail: this.GLOBAL_CONTACT_EMAIL })
      }
    },
  },
  methods: {
    submitForm() {
      this.$refs.formData.validate(valid => {
        if (valid) {
          this.formData.metaTraderLogin = this.accountNumber
          this.formData.currentLeverage = this.leverage
          this.formData.newLeverage = this.formData.newLeverage.replace(' : 1', '')
          if (this.method == 'live') {
            this.updateLeverage()
          } else if (this.method == 'demo') {
            if (this.formData.newLeverage < this.formData.currentLeverage) {
              this.checkVisible = true
            } else {
              this.updateDemoLeverage()
            }
          }
        } else {
          return false
        }
      })
    },
    updateLeverage() {
      apiUpdate_leverage(this.formData)
        .then(resp => {
          this.successMethod(resp)
        })
        .catch(err => {
          this.errorMethod(err)
        })
        .finally(() => {
          this.resetForm(false)
          this.confirmVisible = true
        })
    },
    updateDemoLeverage() {
      apiUpdate_leverage_demo({
        currentLeverage: this.formData.currentLeverage,
        metaTraderLogin: this.formData.metaTraderLogin,
        newLeverage: this.formData.newLeverage,
      })
        .then(resp => {
          this.successMethod(resp)
        })
        .catch(err => {
          this.errorMethod(err)
        })
        .finally(() => {
          this.resetForm(false)
          this.confirmVisible = true
        })
    },
    successMethod(resp) {
      const returnCode = resp.data
      if (returnCode == 0 || returnCode == 2) {
        this.confirmStatus = returnCode == 0 ? 'auditSuccess' : 'success'
      } else if (returnCode == 3) {
        this.confirmStatus = 'reject'
      } else if (returnCode == 1) {
        this.confirmStatus = 'fail'
      }
      this.$emit('resetTable')
    },
    errorMethod(err) {
      if (err.message.includes('timeout')) {
        this.confirmStatus = 'timeout'
      } else {
        this.confirmStatus = 'fail'
      }
    },
    resetForm(bool) {
      this.$nextTick(() => {
        this.checkVisible = false
        this.visible = bool
        this.$refs.formData.resetFields()
      })
    },
    leverageClick(row) {
      this.visible = true
      row.platform = 'AU'
      this.accountNumber = row.mt4_account
      this.leverage = row.leverage
      this.equity = row.equity
      this.queryAvailableLeverages(row)
    },
    leverageDemoClick(row) {
      this.visible = true
      this.accountNumber = row.accNum
      this.leverage = row.leverage
      this.equity = row.equity
      const data = {
        currency: row.currency,
        equity: row.equity,
        mt4_datasource_id: row.dataSourceId,
      }
      this.queryAvailableLeverages(data)
    },
    queryAvailableLeverages(account) {
      apiQueryAvailableLeverages(account).then(resp => {
        this.avaliableLeverages = resp.data
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.change_leverage_drawer {
  .reminder-box {
    .desc {
      font-size: 12px;
      color: $text-secondary;
    }
  }
  .tnc {
    .el-form-item__content {
      .el-checkbox__label {
        @include rtl-sass-prop(padding-left, padding-right, 8px);
        .tnc_label {
          display: flex;
          align-items: center;
          > p {
            color: #1776e6 !important;
            cursor: pointer;
          }
        }
      }
      .tnc_text_box {
        p {
          font-size: 12px;
          color: $text-secondary;
          line-height: 22px;
          a {
            color: $text-link;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .info {
    .el-form-item__content {
      font-size: 12px;
      color: $text-secondary;
    }
  }
}
</style>
